.card-dashboard {
  // width: 100% !important;
  border-radius: 10px;
  height: 17rem !important;
  background-color: red !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart {
  width: 100% !important;
}

.main-dashboard-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4%;
}

.sellall {
  border: none;
  outline: none;
  background: #e95a5a;
  border-radius: 4px;
  color: white;
  width: 30%;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
}
.sellall:hover {
  border: 1px solid #e95a5a;
  background-color: white;
  color: #e95a5a;
  overflow: hidden;
}

.sellalls {
  border: none;
  outline: none;
  background: #e95a5a;
  border-radius: 4px;
  color: white;
  width: 80%;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
}

.sellalls:hover {
  border: 1px solid #e95a5a;
  background-color: white;
  color: #e95a5a;
  overflow: hidden;
}
