@use "../../../../sass/abstract/" as *;

.login-content {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden !important;
}
.button-forgetpassword {
    display: flex;
    align-items: center;
    justify-content: center;
}

.reset-buttons {
    width: 32%;
    background-color: #e95a5a !important;
}
.modal-bodys {
    padding-top: 10% !important;
}

.back-to-login {
    background-color: #e95a5a !important;
    @include xsBreakPoint {
        width: 60% !important;
    }
    @include smBreakPoint {
        width: 100% !important;
    }
    @include mdBreakPoint {
        width: 100% !important;
    }
    @include lgBreakPoint {
        width: 100%;
    }
    @include xlBreakPoint {
        padding: 0;
    }
}
.forget-password-button-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include xsBreakPoint {
        flex-direction: column;
        gap: 20px;
    }
    @include mdBreakPoint {
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
    }
}
