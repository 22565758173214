@use "../../../../sass/abstract/" as *;

.subscribeplanmainsection {
  margin-bottom: 10%;
  .subscribemainpage {
    width: 100%;
    height: 100%;
    // border: 3px solid blue;
    background-color: #eaeef6;

    padding: 4%;
    border-radius: 10px;
    @include mdBreakPoint {
      padding: 2%;
    }
    @include lgBreakPoint {
      padding: 2%;
    }

    .subtitle {
      text-align: center;
      padding-bottom: 5%;
      font-size: 1.6rem;
      @include xsBreakPoint {
        padding-bottom: 10%;
        padding-top: 5%;
        font-size: 1.4rem;
      }

      @include smBreakPoint {
        padding-bottom: 10%;
        padding-top: 5%;
        font-size: 1.4rem;
      }
    }

    .subscribecard {
      width: 20%;
      height: 100%;
      border-radius: 4px;
      // margin: 0 auto;

      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      background-color: white;

      cursor: pointer;
      padding: 3% 1%;
      @include xsBreakPoint {
        width: 95%;
      }
      @include smBreakPoint {
        width: 85%;
      }
      .subname {
        text-align: center;
        font-size: 1.5rem;
        @include mdBreakPoint {
          font-size: 1.1rem;
        }
        @include lgBreakPoint {
          font-size: 1.2rem;
        }
        @include xlBreakPoint {
          font-size: 1.2rem;
        }
      }
      .subprice {
        text-align: center;
        padding: 5% 0px;
        margin-bottom: 5%;
        .subpricename {
          font-size: 2rem;
          font-weight: 700;
          color: black;
          @include mdBreakPoint {
            font-size: 1.4rem;
          }
          @include lgBreakPoint {
            font-size: 1.6rem;
          }
          @include xlBreakPoint {
            font-size: 1.6rem;
          }
        }
      }
      .subscribebutton {
        width: 100%;
        border: none;
        outline: none;
        color: #e95a5a;
        padding: 7px;
        border-radius: 5px;
        border: 1px solid #e95a5a;
        background-color: white;
        cursor: pointer;
      }
      .subdes {
        font-size: 14px;
        text-align: center;
        padding-top: 10%;
      }
    }
    .subscribecard:hover {
      transform: scale(1.1);
    }
    .subscribecard:nth-child(1) {
      border-top: 4px solid #e5cd3b;
    }
    .subscribecard:nth-child(2) {
      border-top: 4px solid #57caf8;
    }
    .subscribecard:nth-child(3) {
      border-top: 4px solid #7516d9;
    }
    .subscribecard:nth-child(4) {
      border-top: 4px solid #e38172;
    }
    .subscribecard:nth-child(5) {
      border-top: 4px solid #55d774;
    }
  }

  .btn-subcribed {
    width: 100%;
    border: none;
    outline: none;
    color: white;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #e95a5a;
    background-color: #e95a5a;
    cursor: no-drop !important;
    @include mdBreakPoint {
      font-size: 1rem;
      padding: 4px 10px !important;
    }
  }

  .btn-subcribe:hover {
    background-color: #e95a5a;
    color: white;
  }

  .btn-subcribe {
    width: 100%;
    border: none;
    outline: none;
    color: #e95a5a;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #e95a5a;
    background-color: white;
    cursor: pointer;
    @include mdBreakPoint {
      font-size: 1rem;
      padding: 4px 10px !important;
    }
  }

  .btn-subcribe:hover {
    background-color: #e95a5a;
    color: white;
  }
}
