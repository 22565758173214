@use "../abstract" as *;

// back button start section

.back-btn {
    width: 100%;
    color: #e95a5a !important;
    background-color: white !important;
    border: 1px solid #e95a5a !important;
    cursor: pointer;
    @include xsBreakPoint {
        width: 100% !important;
    }
    @include smBreakPoint {
        width: 100% !important;
    }
    @include mdBreakPoint {
        width: 100% !important;
    }
    @include lgBreakPoint {
        width: 100% !important;
    }
    @include xlBreakPoint {
        width: 100% !important;
    }
}
.back-btn:hover {
    background-color: #e95a5a !important;
    cursor: pointer;
    color: white !important;
}

// back button end section

// submit button start section

.submit-btn {
    background-color: #e95a5a !important;
    cursor: pointer;
    color: white !important;
    width: 30%;
    @include xsBreakPoint {
        width: 100% !important;
    }
    @include smBreakPoint {
        width: 40% !important;
    }
    @include mdBreakPoint {
        width: 100% !important;
    }
    @include lgBreakPoint {
        width: 50% !important;
    }
    @include xlBreakPoint {
        width: 50% !important;
    }
}
.submit-btn:hover {
    background-color: white !important;
    cursor: pointer;
    color: #e95a5a !important;
}

// submit button end section

// button gap start section

.button-gap-section {
    display: flex;
    justify-content: space-between;
}

// button gap end section

// next button

.next-btn {
    background-color: #e95a5a !important ;
    cursor: pointer;
    color: white !important;
}
.next-btn:hover {
    background-color: white !important;
    cursor: pointer;
    color: #e95a5a !important;
}

// upload button start

.upload-btn {
    background-color: #e95a5a !important ;
    cursor: pointer;
    color: white !important;
}
.upload-btn:hover {
    background-color: white !important;
    cursor: pointer;
    color: #e95a5a !important;
}

// upload button end

// active icon in svg start

.width {
    width: 20px;
}
// active icon in svg end

@font-face {
    font-family: "icomoon";
    src: url("../../assets/fonts/fonts/icomoon.eot?xz3w1d");
    src:
        url("../../assets/fonts/fonts/icomoon.eot?xz3w1d#iefix") format("embedded-opentype"),
        url("../../assets/fonts/fonts/icomoon.ttf?xz3w1d") format("truetype"),
        url("../../assets/fonts/fonts/icomoon.woff?xz3w1d") format("woff"),
        url("../../assets/fonts/fonts/icomoon.svg?xz3w1d#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-profile:before {
    content: "\e900";
}
.icon-home:before {
    content: "\e901";
}
.icon-calender:before {
    content: "\e902";
}
.icon-user-mng:before {
    content: "\e903";
}
.icon-logout:before {
    content: "\e904";
}
.icon-hidden:before {
    content: "\e905";
}
.icon-show:before {
    content: "\e906";
}
.icon-edit:before {
    content: "\e907";
}
.icon-delete:before {
    content: "\e908";
}
.icon-product:before {
    content: "\e909";
}
.icon-service:before {
    content: "\e90a";
}
.icon-seller-approval:before {
    content: "\e90b";
}
.icon-dashboard:before {
    content: "\e90c";
}

.icon-size {
    font-size: 20px;
}

.image-circle {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;
}
