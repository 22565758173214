@use "../../../sass/abstract/" as *;

.signup-main-section {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #e75b5e;
    @include smBreakPoint {
        overflow-y: auto;
        height: 100vh;
    }
    .inside-signup-section {
        width: 100%;
        height: 100%;
        display: flex;

        .left-signup-section {
            width: 50%;
            // border: 2px solid red;
            position: relative;
            @include xsBreakPoint {
                width: 100%;
            }
            @include smBreakPoint {
                width: 100%;
            }
            .logo {
                position: absolute;
                top: 10%;
                left: 5%;
            }
            .circle {
                position: absolute;
                top: 30%;
                left: 10%;
                @include mdBreakPoint {
                    top: 40% !important;
                }
                .circles {
                    width: 90%;
                    @include mdBreakPoint {
                        width: 70% !important;
                        top: 20% !important;
                    }
                }
            }
            .sellers {
                position: absolute;
                bottom: 10%;
                left: 10%;
            }
            .we {
                font-size: 38px !important;
                font-weight: bold;
                color: #ff9c9e;
                @include mdBreakPoint {
                    font-size: 30px !important;
                }
            }
            .seller {
                font-size: 38px !important;
                font-weight: bold;
                color: #f3f3f4;
                @include mdBreakPoint {
                    font-size: 30px !important;
                }
            }

            .auth-card {
                padding: 0px !important;
                @include xsBreakPoint {
                    padding: 10% !important;
                }
                @include smBreakPoint {
                    padding: 13% !important;
                }
            }
        }
        .right-signup-section {
            width: 50%;
            // border: 2px solid blue;
            background-color: white;
            border-top-left-radius: 35px !important;
            border-bottom-left-radius: 35px !important;
            background: linear-gradient(144.66deg, #e9e9e9 -8.36%, #ffffff 44.59%);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            position: relative;

            @include xsBreakPoint {
                width: 100%;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
            @include smBreakPoint {
                width: 100%;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
            .circleone-image {
                position: absolute;
                right: 0;
                width: 20%;
            }
            .circletwo-image {
                position: absolute;
                bottom: 0%;
                left: 3%;
            }

            .inside-right-singup-section {
                width: 80%;
                height: 100%;
                overflow: hidden;
                margin: auto;
                overflow-y: auto;
                .first-right-signup-section {
                    width: 100%;
                    height: 20%;
                    // border: 2px solid olive;
                    position: relative;
                    background-image: url("../../../assets/loginLogos/quatation.svg");
                    background-repeat: no-repeat;
                    // height: auto;
                    background-size: contain;
                    // text-align: center;
                    bottom: 0;
                    text-align: center;
                    @include xsBreakPoint {
                        bottom: 0 !important;
                        height: 20%;
                    }

                    .qutations-section-first {
                        padding-left: 3%;
                        @include xsBreakPoint {
                            padding-left: 7%;
                        }
                        @include smBreakPoint {
                            padding-left: 5%;
                        }
                        @include mdBreakPoint {
                            padding-left: 7%;
                        }
                        @include lgBreakPoint {
                            padding-left: 6%;
                        }
                        @include xlBreakPoint {
                            padding-left: 6%;
                        }
                        .and-love-by {
                            position: absolute;
                            font-weight: 500;
                            color: #092133;
                            font-size: 1.5rem !important;
                            bottom: 0;
                        }
                        .customer {
                            font-weight: 500;
                            color: #e75b5e;
                            font-size: 1.5rem;
                        }
                    }
                }
                .second-right-signup-section {
                    width: 100%;
                    height: 75%;

                    .contact-number-section {
                        display: flex;
                        // align-items: center;
                        // border: 2px solid red;
                        .box-number-section {
                            background-color: #f9f9f9;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #8a92a6;
                            border-radius: 5px;
                            padding: 0px 10px;
                        }
                    }
                    .signin-section {
                        color: #1e1e24;
                        font-weight: 700;
                    }
                    .reset-button {
                        width: 25% !important;
                        background-color: #e75b5e;
                        @include xsBreakPoint {
                            width: 45% !important;
                        }
                        @include smBreakPoint {
                            width: 35% !important;
                        }
                        @include mdBreakPoint {
                            width: 45% !important;
                        }
                    }
                }
                .third-right-signup-section {
                    width: 100%;
                }
            }
            .inside-right-singup-section::-webkit-scrollbar {
                display: none;
            }

            /* Hide scrollbar for IE, Edge and Firefox */
            .inside-right-singup-section {
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        }
    }
}

.aggregate {
    // color: #e95a5a;
    width: 38%;
    padding: 2% 0px;
}

.termsofuse {
    cursor: pointer;
    padding-top: 2px;
    color: #e95a5a;
    // border: 1px solid red;
    border-bottom: 1px solid #e95a5a;
}
.termsofuse:hover {
    text-decoration: dashed !important;
}
