@use "../../../../../../sass/abstract/" as *;

.status-box {
    width: 15px !important;
    height: 15px !important;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.samples
{
    display: flex;
    align-items: center;
    justify-content: center;
}