@use "../../../../sass/abstract/" as *;

.view-ticket-fullcard {
    height: 60vh !important;
    overflow: auto;
    padding-bottom: 1% !important;

    .view-ticket-card {
        background-color: #F5F5F5 !important;
        padding: 1.5% !important;
        margin-bottom: 3vh !important;
        border: 1px solid #D1D1D1;
    }

    .view-ticket-cards {
        background-color: rgba(255, 75, 75, 0.25) !important;
        padding: 1.5% !important;
        margin-bottom: 3vh !important;
        border: 1px solid rgba(233, 90, 90, 0.7);
    }
}

.text-bold {
    font-weight: bold;
}

.no-ticket {
    text-align: center;
    margin: 2rem;
}