@use "../../../../sass/abstract/" as *;
.singin-main-section-otp {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #e75b5e;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .box-otp-check {
        border: 2px solid white;
        width: 28% !important;
        height: auto !important;
        padding: 45px 0px;
        border-radius: 13px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        z-index: 98989898989898;
        @include xsBreakPoint {
            width: 90% !important;
        }
        @include smBreakPoint {
            width: 65% !important;
            height: auto !important;
        }
        @include mdBreakPoint {
            width: 55% !important;
            height: auto !important;
        }
        @include lgBreakPoint {
            width: 45% !important;
            height: auto !important;
        }
        @include xlBreakPoint {
            width: 32% !important;
            height: auto !important;
            padding: 30px 15px !important;
        }

        .login-logo-section {
            color: #121e49;
            font-weight: 500;
            text-align: center;
            padding-bottom: 0.897%;
            cursor: pointer;
            font-size: 1.4rem;
        }
        .we-have-otp-section {
            padding-top: 3%;
            text-align: center;
            .one-time {
                font-weight: 600;
            }
        }
        .please-enter-otp-section {
            text-align: center;
            padding-top: 3%;
            color: #121e49;
            font-weight: 500;
            .otp-box-inputs {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 4%;
                .inputStyle {
                    margin-left: 13px;
                    margin-right: 13px;
                    border: none;
                    width: 40px !important  ;
                    outline: none;
                    background-color: white !important;
                    border-bottom: 1px solid #121e49;
                    z-index: 9989898989898988989898989;
                    @include xsBreakPoint {
                        margin-left: 7px;
                        margin-right: 7px;
                    }
                    @include smBreakPoint {
                        margin-left: 9px;
                        margin-right: 9px;
                    }
                    @include mdBreakPoint {
                        margin-left: 13px;
                        margin-right: 13px;
                    }
                    @include lgBreakPoint {
                        margin-left: 13px;
                        margin-right: 13px;
                    }
                    @include xlBreakPoint {
                        margin-left: 8px;
                        margin-right: 8px;
                    }
                }
                .focusStyle {
                    background-color: transparent !important;
                    color: black !important;
                }
            }
            .invalid-otp {
                color: red;
            }
        }
        .button-section-otp {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15%;
            @include xsBreakPoint {
                gap: 5% !important;
                padding-top: 5%;
            }
            .resend-otp-button {
                background-color: white;
                color: #e75b5e;
                border: 2px solid #e75b5e;
                @include xsBreakPoint {
                    font-size: 0.819rem !important;
                    width: 100%;
                }
            }
            .verify-otp-button {
                background-color: #e75b5e;
                @include xsBreakPoint {
                    font-size: 0.819rem !important;
                    width: 100%;
                }
            }
        }
        .minutes-section {
            color: #4169e1;
            font-weight: 500;
        }
        .back-to-logins {
            text-align: center;
            font-weight: 500;
            color: #e75b5e;
            border-bottom: 2px solid #e75b5e;
            width: 35%;
            margin: 0 auto;
            cursor: pointer;
            padding-bottom: 1.1%;
            padding-top: 3%;
            @include xsBreakPoint {
                width: 40%;
            }
            @include smBreakPoint {
                padding-top: 5%;
                width: 40%;
            }
            @include mdBreakPoint {
                padding-top: 5%;
                width: 40%;
            }
            @include lgBreakPoint {
                padding-top: 2%;
                width: 30%;
            }
            @include xlBreakPoint {
                padding-top: 5%;
                width: 35%;
            }
        }
    }

    .logo-otp {
        position: absolute;
        top: 10%;
        left: 5%;
        width: 12%;
        @include xsBreakPoint {
            width: 35%;
            top: 5% !important;
        }
        @include smBreakPoint {
            width: 25%;
            top: 5% !important;
        }
        @include mdBreakPoint {
            width: 20%;
            top: 5% !important;
        }
        @include lgBreakPoint {
            width: 15%;
            top: 5% !important;
        }
    }
    .circles-otp {
        position: absolute;
        top: 35%;
        left: 12%;
        width: 14%;
        @include mdBreakPoint {
            left: 4%;
        }
        @include lgBreakPoint {
            left: 7%;
        }
        @include xlBreakPoint {
            left: 7%;
        }
    }
    .last-logo {
        position: absolute;
        right: 0;
        top: 0;
    }
    .sellers-otp {
        position: absolute;
        bottom: 10%;
        left: 6%;
        @include xsBreakPoint {
            bottom: 2% !important;
        }
        @include smBreakPoint {
            bottom: 5% !important;
        }
        @include mdBreakPoint {
            bottom: 5% !important;
            left: 10%;
        }
        @include lgBreakPoint {
            bottom: 5% !important;
            left: 10%;
        }
        @include xlBreakPoint {
            bottom: 5% !important;
            left: 6%;
        }
        .we {
            font-size: 2rem !important;
            @include xsBreakPoint {
                font-size: 1.7rem !important;
            }
        }
        .seller-otp-text {
            font-size: 2rem !important;
            color: white;
            @include xsBreakPoint {
                font-size: 1.7rem !important;
            }
        }
    }
}
