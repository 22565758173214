@use "../../../../sass/abstract/" as *;

.query-success {
    color: #e95a5a !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}
.lettercaps
{
    text-transform:uppercase;
    
}