@use "../../../../sass/abstract/" as *;

.inside {
  display: flex;
  justify-content: space-between;
  height: 100%;

  .product {
    font-size: 28px;
    font-weight: 600;
    @include xsBreakPoint {
      font-size: 1.3rem;
    }
  }
  .show {
    border: 1px solid #e95a5a;
    border-radius: 10px;
    color: #e95a5a;
    width: 127px;
    height: 48px;
    background-color: white;

    @include smBreakPoint {
      // width: 20%;
      height: 40px;
      // margin-left: 10%;
    }
    @include mdBreakPoint {
      // width: 14%;
      height: 40px;
    }
    @include xsBreakPoint {
      // width: 10%;
      height: 40px;
    }
  }
}
.insidecontainer {
  display: flex;
  gap: 3%;
  margin-top: 3%;
  padding-bottom: 5%;
  @include smBreakPoint {
    display: contents;
  }
  @include mdBreakPoint {
    display: contents;
  }
  @include xsBreakPoint {
    display: contents;
  }
  .leftinsidesection {
    width: 30%;
    border: 1px solid #9f9f9f;
    border-radius: 10px;
    padding: 0px 20px;
    @include smBreakPoint {
      width: 100%;
      margin-top: 2%;
    }
    @include mdBreakPoint {
      width: 100%;
      margin-top: 2%;
    }
    @include xsBreakPoint {
      width: 100%;
      margin-top: 2%;
    }
    .leftContainer1 {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 2%;

      .shipmentpara {
        margin-bottom: 0%;
        font-size: 16px;
        font-weight: 400;
        opacity: 0.6;
        padding-top: 4%;
      }
    }
  }
  .rightinsidesection {
    width: 65%;
    gap: 10px;
    border: 1px solid #9f9f9f;
    border-radius: 10px;
    padding: 2%;
    @include smBreakPoint {
      width: 100%;
      margin-top: 2%;
    }
    @include mdBreakPoint {
      width: 100%;
      margin-top: 2%;
    }
    @include xsBreakPoint {
      width: 100%;
      margin-top: 2%;
    }

    .rightContainer1 {
      width: 100%;
      gap: 30px;
      display: flex;
      @include smBreakPoint {
        display: contents;
      }
      @include xsBreakPoint {
        display: contents;
      }
      @include lgBreakPoint {
        display: grid;
      }
      .rightContainersection1 {
        width: 60%;
        padding: 1%;
        @include smBreakPoint {
          width: 95%;
        }
        @include lgBreakPoint {
          width: 100%;
        }
        @include xsBreakPoint {
          width: 100%;
        }

        .innerstyle {
          display: flex;
          justify-content: space-between;
        }
        .innercorrier {
          font-size: 16px;
          font-weight: 400;
          opacity: 0.6;
          color: #121212;
        }

        .inner {
          display: flex;
          font-size: 16px;
          font-weight: 400;
          justify-content: space-between;
        }
        .hrcontain {
          border-style: dotted;
        }
        .receipt {
          font-size: 16px;
          font-weight: 400;
          opacity: 0.6;
          color: #121212;
          margin-bottom: 0%;
        }
      }
      .rightContainersection2 {
        width: 100%;
        .rightinsidecontainer {
          display: flex;
          .img1 {
            width: 120px;
            height: 120px;
            border-radius: 15px;
            background-color: #c4c4c4;
            @include mdBreakPoint {
              width: 110px;
              height: 110px;
            }
          }
        }
        .rightinsidecontainer1 {
          margin-left: 4%;
          @include lgBreakPoint {
            margin-left: 10%;
          }
          @include xsBreakPoint {
            margin-left: 10%;
          }
          .paracontent {
            margin-bottom: 1%;
            font-size: 18px;

            width: 100%;
          }
          .paracontent2 {
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            margin-bottom: 1%;
          }
          .dollar {
            font-size: 16px;
            font-weight: 500;
          }
          .cancel {
            color: #e95a5a;
            margin-top: 14%;
            background: rgba(233, 90, 90, 0.2);
            padding: 8px 14px;
            border-radius: 10px;
            cursor: pointer;
            @include mdBreakPoint {
              margin-top: 0px;
            }
          }
        }
      }
    }
  }
}

.kalai {
  // border:2px solid blue !important;
  color: red !important;
}

.modelremove {
  width: 100%;

  .insidemodelpopup {
    // border:3px solid red;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
  }
  .buttonsections {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding-top: 4%;
    padding-bottom: 5%;
    .buttonscancel {
      outline: none;
      cursor: pointer;
      background-color: white;
      color: rgba(233, 90, 90, 1);
      border: 1px solid rgba(233, 90, 90, 1);
      padding: 8px 30px;
      border-radius: 10px;
      font-weight: 600;
    }
    .buttonsremove {
      outline: none;
      cursor: pointer;
      background-color: rgba(233, 90, 90, 1);
      color: white;
      border: none;
      padding: 8px 30px;
      border-radius: 10px;
      font-weight: 600;
    }
  }
}
