@use "../../../../../../sass/abstract/" as *;

.btn-primarys {
  border: 2px solid #e95a5a !important;
  background-color: white !important;
  cursor: pointer;
  color: #e95a5a !important;
}

.step {
  width: 40px;
  height: 40px;
  border: 2px solid #e95a5a;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #8a92a6 !important;
  background-color: #ebeefd !important;
  position: relative;
}

.step.completed::after {
  content: "";
  border: 2px solid #e95a5a;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
}

.step.completed {
  background-color: #e95a5a !important;
  color: white !important;
}

.kalai-box {
  // border: 2px solid blue;
  padding: 10px 0px;
  width: 60%;
  margin: 0 auto;

  @include xsBreakPoint {
    width: 85% !important;
    margin: 0 auto;
  }

  @include smBreakPoint {
    width: 75% !important;
    margin: 0 auto;
  }

  @include mdBreakPoint {
    width: 75% !important;
    margin: 0 auto;
  }

  @include lgBreakPoint {
    width: 59% !important;
    margin: 0 auto;
  }

  @include xlBreakPoint {
    width: 62% !important;
    margin: 0 auto;
  }
}

.kalai {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15vh !important;
  position: absolute;
  text-align: center;
  // border: 2px solid blue;
  width: 130px;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #232d42 !important;
  font-weight: 500;

  @include xsBreakPoint {
    font-size: 12px !important;
    width: 20px;
    margin-top: 45%;
  }
  @include smBreakPoint {
    font-size: 12px !important;
    width: 20px;
    margin-top: 45%;
  }
  @include mdBreakPoint {
    font-size: 12px !important;
    width: 20px;
    margin-top: 45%;
  }
  @include lgBreakPoint {
    font-size: 12px !important;
    width: 20px;
    margin-top: 45%;
  }
  @include xlBreakPoint {
    font-size: 12px !important;
    width: 20px;
    margin-top: 45%;
  }
}

.backs {
  border: 2px solid #e95a5a !important;
  color: #e95a5a !important;
}

.does {
  color: #232d42 !important;
}

.finish {
  background: #e95a5a !important;
  border: none !important;
}

.main-camera-section {
  width: 100% !important;
  height: 100% !important;
  border: 2px dashed #e95a5a;
  border-radius: 5px;
  padding: 30px 30px;
  cursor: pointer;

  .inside-camera-section-upload {
    background-color: rgba(233, 90, 90, 0.2);
    padding: 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    @include xsBreakPoint {
      padding: 40px 30px;
    }
  }
}

.main-camera-sections {
  width: 100% !important;
  height: 100% !important;
  border: 2px dashed #e95a5a;
  border-radius: 5px;
  padding: 20px 10px;
  cursor: pointer;

  .inside-camera-section-upload {
    background-color: rgba(233, 90, 90, 0.2);
    padding: 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    @include xsBreakPoint {
      padding: 40px 30px;
    }
  }
}

.productid_tag {
  font-size: 12px;
  font-weight: 600;
  padding: 20px;
}

.productid_tag button {
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.image-section-show {
  padding-top: 5%;
  border-radius: 10px !important;

  .container {
    position: relative;
    // width: 100%;
    // max-width: 400px;
    border: 2px dashed #e95a5a;
    border-radius: 10px;
    padding: 10px;
  }

  .image {
    display: block;
    width: 100%;
    height: 150px;
    border-radius: 10px !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
  }

  .container:hover .overlay {
    opacity: 1;
  }

  .icon {
    color: #e95a5a;
    font-size: 100px;
    position: absolute;
    top: 10%;
    right: 0;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    cursor: pointer;
  }

  .fa-user:hover {
    color: #e95a5a;
  }
}

.product-card-container {
  //   border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-box-poistion {
  position: relative !important;
}
.remove-image-box {
  position: absolute;
  top: 0;
  right: 25%;
  z-index: 98 !important;
}
