@use "../../../../../sass/abstract/" as *;

.exports {
  width: 50%;
  border-radius: 12px;
  padding: 7px;
  border: 1px solid red;
}

.box-shdow {
  padding: 5px 15px !important;
  border-radius: 10px;
  // opacity: 0.;
  // font-weight: 600;
}

.cursor-texts {
  cursor: pointer;
}

.form-control {
  padding: 9px !important;
}

.select {
  padding: 10px !important;
  border: 2px solid red !important;
}

.date-picker {
  display: flex;
  align-items: center;

  .to {
    margin-left: 9px;
    margin-right: 9px;
    @include xsBreakPoint {
      margin: 0px 3px !important;
    }
  }
}

.formsbuttons {
  display: flex;
  align-items: center;
  padding-left: 2%;
  justify-content: center;
}

.nexts {
  width: 100% !important;
  padding: 8px 30px !important;
  @include xsBreakPoint {
    padding: 9px 10px !important;
  }
}

.nexts:hover {
  background-color: white !important;
  color: #e95a5a !important;
  cursor: pointer !important;
  border: 1px solid #e95a5a !important;
}

.confirm {
  background: rgba(255, 61, 0, 0.1);
  color: #ff3d00;
  opacity: 0.8;
  padding: 6px 20px !important;
  border-radius: 10px;
  font-size: 0.876rem !important;
  width: auto;
  text-wrap: nowrap;
}

.neworders {
  background: rgba(255, 61, 0, 0.1);
  color: #fecf9d;
  opacity: 0.8;
  padding: 6px 20px !important;
  border-radius: 10px;
  font-size: 0.876rem !important;
  width: auto;
  text-wrap: nowrap;
}

.progresss {
  background: rgba(255, 61, 0, 0.1);
  color: #e95a5a;
  opacity: 0.8;
  padding: 6px 20px !important;
  border-radius: 10px;
  font-size: 1rem !important;
}

.cancel {
  background: rgba(255, 61, 0, 0.1);
  color: #e95a5a;
  opacity: 0.8;
  padding: 6px 15px !important;
  border-radius: 10px;
  font-size: 0.876rem !important;
  width: auto;
  text-wrap: nowrap;
}

.delivery {
  background-color: green;
  color: white;
  padding: 6px 20px !important;
  border-radius: 10px;
  font-size: 0.876rem !important;
  width: auto;
  text-wrap: nowrap;
}
