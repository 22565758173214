@use "../../../../sass/abstract/" as *;

.termsmainsection {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 3%;
    padding-bottom: 5%;
    .insidetermssection {
        width: 80%;
        margin: 0 auto;
        color: black !important;
    }
}
