@use "../abstract/" as *;

head,
tbody,
tfoot,
tr,
td,
th {
    white-space: normal !important;
}

tr:nth-child(odd) {
    background-color: white;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

th {
    background-color: lightseagreen;
}
