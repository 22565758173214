@use "../../../../sass/abstract/" as *;
.actives {
  background-color: #e95a5a !important;
  color: white !important;
  opacity: 0.9;
  font-weight: 500;
}
.n {
  // background-color: blue !important;
  color: #e95a5a !important;
}
.btn-blue {
  background-color: #e95a5a !important;
  border: none !important;
}
.cards {
  background-color: #f9f9f9;
  // border: 2px solid #f9f9f9 !important;
  border-radius: 10px !important;
  padding: 5px !important;
  width: 100% !important;
  cursor: pointer;
}
.btn-end {
  display: flex;
  justify-content: flex-end;
}
.error-message {
  color: red !important;
  font-size: 12px !important;
}
.back {
  background-color: #6c6d6e !important;
  color: white !important;
  // border: 1px solid #6c6d6e !important;
  border: none !important;
}
::placeholder {
  color: #c6c6c6 !important;
}

input {
  color: black !important;
}
input,
textarea {
  color: black !important;
}

select {
  color: black !important;
}
.email-disabled {
  color: #c6c6c6 !important;
}
.uploadfiles {
  width: 42px;
  height: 42px;

  cursor: pointer;
  object-fit: cover;
}

.userdata {
  width: 20% !important;
  // border: 2px solid red;
  @include xsBreakPoint {
    width: 100%;
    display: none !important;
  }
}
.userdata1 {
  width: 25% !important;
  @include xsBreakPoint {
    width: 100% !important;
    margin-bottom: 4% !important;
    // display: none !important;
  }
}

.main-card {
  height: 70px !important;
  padding-bottom: 5%;
}
.main-card-sub-container1 {
  width: 95% !important;
  margin: 0 auto !important;
  display: flex !important;
  overflow: hidden !important;
  position: absolute !important;
  top: 32% !important;
  align-items: center !important;
  justify-content: center !important;
  .sub-container-2 {
    width: 24%;
    border-radius: 10px;
    // background-color: white;
    cursor: pointer;
    border-top: none;
    min-height: 400px;
    border: 1px solid #ffd8d8;
    outline: none;
    @include xsBreakPoint {
      width: 100%;
      margin-bottom: 2%;
    }
    @include smBreakPoint {
      width: 100%;
      margin-bottom: 2px;
    }
  }
  .btn-subscribe-2 {
    width: 100%;
    border: none;
    outline: none;
    color: #e95a5a;
    background-color: white;
    cursor: pointer;
    border: 1px solid #e95a5a;
    border-radius: 5px;
    padding: 7px;
  }
}

.amount {
  font-weight: 600;
  font-size: 1.8rem;
  color: #e95a5a;
}
.colors {
  font-weight: 600;
  font-size: 2rem;
  color: white;
}

.c {
  font-weight: 600;
  text-align: center;
  font-size: 2rem;
}
.d {
  font-weight: 600;
  text-align: center;
  font-size: 1.7rem;
  color: black;
}

.descs {
  padding-left: 0;
  // border: 2px solid red;
}
