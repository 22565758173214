@use "../../../../../sass/abstract/" as *;
.add-variations {
  border: 2px dashed #e75b5e !important;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 6px;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
}
.variation-box-inside-edit {
  border: 2px solid #f9f9f9;
  background-color: #f9f9f9;
  padding: 10px 10px;
  border-radius: 3px;
  .inside-box-inside-edit-section {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px;
    .titles {
      color: black;
      font-weight: 550;
    }
    .edit-delete-section {
      display: flex;
      gap: 10px;

      .delete-button-cursor {
        cursor: pointer;
      }
      .delete-button-over {
        border-right: 2px solid #8a92a6;
        border-radius: 10px;
      }
    }
  }
  .date-start-section {
    color: black !important;
    font-weight: 500;
    cursor: pointer;
  }
  .description-box {
    border: 3px solid blue !important;
  }
}
.month {
  cursor: pointer;
}
.text-sizes {
  font-size: 0.897rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addmoreslots {
  color: #e75b5e !important;
  border-bottom: 1px solid #e75b5e;
  width: 28%;
  font-weight: 700;
  cursor: pointer;
}
.description {
  // border: 1px solid blue !important;
  height: 200px;
}

.variation-section-inside {
  padding: 10px 20px;
  .checkedcolor {
    color: #e75b5e !important;
    cursor: pointer;
  }
  .per-day-year-section {
    display: flex;
    width: 100%;
    gap: 20px;
    padding: 1px;
    .count-sections {
      width: 15%;
      // border: 2px solid red;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      cursor: pointer;
      background-color: #ebeefd !important;
      border-radius: 10px;
      .plus {
        color: #3a57e8 !important;
        background-color: transparent;
      }
    }
    .form-sections {
      width: 85%;
    }
  }
  .duration-section-years {
    display: flex;
    .month-section-years {
      display: flex;
      width: 100%;
      gap: 40px;
    }
  }
  .date-section-form {
    display: flex;
    gap: 10px;
  }
  .months {
    display: flex;
    flex-direction: column;
    // gap: 15px;
  }
  .select-months {
    border: 1px solid #e95a5a;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    color: white;
    background-color: #e95a5a;
    border-radius: 20px;
    cursor: pointer;
  }
  .inactive {
    border: 1px solid #e95a5a;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    color: #e95a5a;
    border-radius: 20px;
    cursor: pointer;
  }
}

.serviceform {
  background-color: #f9f9f9 !important;
  border: none !important;
  border-radius: 5px !important;
}

.control {
  display: inline-flex;
  position: relative;
  margin: 5px;
  cursor: pointer;
  border-radius: 99em;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  svg {
    margin-right: 6px;
    border-radius: 50%;
    box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.2);
  }
}

.control__content {
  border: 1px solid #e95a5a;
  padding: 10px;
  text-align: center;
  font-weight: 500;
  color: #e95a5a;
  // background-color: #e95a5a;
  border-radius: 20px;
  cursor: pointer;
  width: 120px;
  background-color: white;
}

.control input:focus ~ .control__content {
  background-color: white;
}

.control input:checked ~ .control__content {
  background-color: #e95a5a;
  color: white;
}

.countsection {
  border: none;
  width: 100%;
  background-color: none;
  outline: none;
  text-align: center;
  background-color: #ebeefd !important;
}

.leftsectionplus {
  width: 30%;
  // border: 3px solid red;
  text-align: center;
  font-size: 1.5rem;
}

.middlesectionplus {
  width: 40%;
  // border: 3px solid blue;
  text-align: center;
}
.rightsectionplus {
  width: 30%;
  margin: 0 auto;
  text-align: center;
  font-size: 1.5rem;
}

// slots

.mainslots {
  width: 100%;
  display: flex;
  gap: 20px;

  .slottimesbox {
    width: 30%;
    border: 1px solid #e95a5a;
    padding: 5px 10px;
    border-radius: 6px;
    position: relative;
    .insidesoltstimeboxs {
      // border: 4px solid blue;
      .insidedeletetimes {
        position: absolute;
        right: -5%;
        top: -10px;
      }
    }
  }
}

.buttonslots {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100%;
  padding-top: 6%;
  .slotboxtimes {
    width: 100%;
    background-color: #e75b5e;
    border: none;
    outline: none;
    padding: 7px 10px;
    border-radius: 8px;
    color: white;
  }
}

.image-box-poistion1 {
  position: relative !important;
  // border: 1px solid blue;
  width: 100%;
}
.remove-image-box1 {
  position: absolute;
  top: 0;
  right: 22%;
  z-index: 98 !important;
}
