@use "../../../../sass/abstract/" as *;

.page-not {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 7%;
    .not-image {
        width: 40vw;
        border-radius: 20%;
        background-color: transparent !important;
    }
    .back-login {
        background-color: #e75b5e !important;
    }
}
