@import '../hope-ui-design-system/variable';

.table {
    .flex-container {

        display: flex;
        flex-wrap: wrap;

        .btn {
            padding: 0.5rem;
            margin: 0.1rem;
            color: #6C6D6E
        }

        .warning {
            background-color: $warning !important;
            color: #000000
        }

    }

    .p-bold {
        color: $form-label-color;
        font-weight: 500;
    }


    .status-box {
        width: 24px;
        height: 24px;
        margin-left: 0.5rem;
        background: #FFC416;
        border-radius: 8px;
    }
}

.user-card-content {
    color: $form-label-color;

    .flex-container {
        display: flex;
        flex-wrap: wrap;
    }

    .permission {
        p {
            margin: auto;
        }

        span {
            margin: auto 0.25rem;
            padding: 0.1rem 1rem;
            background: #E0E0E0;
            border-radius: 24px;
        }
    }

    .contact {
        margin-top: 0.5rem;

        p {
            margin: auto 0;
        }

        span {
            margin: auto 0.25rem;
            color: #3A57E8
        }
    }



}

.action-link {
    color: #3A57E8;
    font-weight: 400;
    font-style: normal;

}

.title-with-icon {
    display: flex;

    width: 150px;

    svg,
    h3 {
        margin: auto
    }
}

.card {
    padding: 2rem;

    .form-control {
        background-color: #F9F9F9;
    }

    .form-label,
    .list-group-item {
        color: $form-label-color;
    }

    .field-container {
        width: 50%;

        margin-bottom: 2rem;

    }

    .margin-left-button {
        margin-left: 2rem;
    }
}